import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        height: '100%',
        backgroundColor: '#e3e3e3',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    timelineContainer: {
        backgroundColor: '#e3e3e3',
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.default,
    },
    title: {
        fontSize: '24px',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    mainHeading: {
        fontSize: '28px',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        padding: '20px'
    },
    description: {
        fontSize: '16px',
        color: theme.palette.primary.main,
    },
    loader: {
        color: theme.palette.primary.main,
    },
}));
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import {useStyles} from "../../Styles/CareActivitiesTimelineStyles";



const Loader = () => {
    const classes = useStyles();

    return (
        <div className={classes.loaderContainer}>
            <CircularProgress className={classes.loader}/>
        </div>
    )
}

export default Loader
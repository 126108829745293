import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import CareActivitiesTimeline from "./Components/CareActivitiesTimeline";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {ThemeProvider, createTheme} from "@mui/material/styles";
import AppTheme from "./Styles/theme";
const queryClient = new QueryClient();
const theme = createTheme(AppTheme);
const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <Router>
                    <Switch>
                        <Route path="/:reference">
                            <CareActivitiesTimeline />
                        </Route>
                        <Route path="*">
                            <h1>Invalid reference</h1>
                        </Route>
                    </Switch>
                </Router>
            </ThemeProvider>
        </QueryClientProvider>
    )
}

export default App;
import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Icon } from '@mui/material';
import { parseISO, format } from 'date-fns';
import Typography from '@mui/material/Typography';
import {useStyles} from "../../Styles/CareActivitiesTimelineStyles";


const Timeline = ({patientCarePlan}) => {
    const classes = useStyles();
    // TODO: Get the following information: activities, disease, plan_pathway, first name, last name, current care_activity === the first care activity
    const current_care_activity = patientCarePlan.isSuccess? patientCarePlan.data.current_care_activity: []
    const scheduled_care_activities = patientCarePlan.isSuccess? patientCarePlan.data.scheduled_care_activities: []
    return (
        <div>
             <div className={classes.mainHeading}>
                {`Scheduled Care Activities ${patientCarePlan.isLoading?'Loading ...': ''}`}
            </div>
            <div className={classes.timelineContainer}>
                <VerticalTimeline layout="1-column">
                    <VerticalTimelineElement
                        key={current_care_activity.activity_id}
                        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                        date={`Scheduled: ${format(
                            parseISO(current_care_activity.activity_date),
                            'dd MMMM yyyy'
                        )}`}
                        icon={
                            <Icon>
                                {current_care_activity.type === 'chronic_care'
                                    ? 'favorite'
                                    : 'fact_check'}
                            </Icon>
                        }
                        iconClassName={classes.icon}
                    >
                        <div className={classes.title}>{current_care_activity.name}</div>
                    </VerticalTimelineElement>
                    {scheduled_care_activities.map((activity) => (
                        <VerticalTimelineElement
                            key={activity.activity_id}
                            date={`Scheduled: ${format(
                                parseISO(activity.activity_date),
                                'dd MMMM yyyy'
                            )}`}
                            icon={
                                <Icon>
                                    {activity.type === 'chronic_care'
                                        ? 'favorite'
                                        : 'fact_check'}
                                </Icon>
                            }
                            iconClassName={classes.icon}
                        >
                            <div className={classes.title}>{activity.name}</div>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
            </div>
        </div>
    );
};

export default Timeline;

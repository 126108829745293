import React from "react";
import Loader from "./Loader";
import {useParams} from "react-router-dom";
import {useCarePlan} from "../../ReactQuery/Queries";
import Timeline from "./Timeline";

const CareActivityTimeline= () =>{
    //TODO: Change to short code when done
    const { reference } = useParams();
    const patientCarePlan = useCarePlan(reference)
    return (
        <React.Fragment>
            {patientCarePlan.isLoading ? <Loader/> :
                patientCarePlan.isError?<h1>Short code {reference} is invalid.</h1>:
                    patientCarePlan.isSuccess && patientCarePlan.data?.current_care_activity?
                        <Timeline patientCarePlan={patientCarePlan}/>
                        :
                        <h1>No upcoming care activities</h1>}
        </React.Fragment>
    )
}

export default CareActivityTimeline

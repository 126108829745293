import axios from 'axios';
const API_URL = process.env.REACT_APP_API_ENDPOINT
export const fetchSceduledCareActivities = (short_code) => {
    return axios
        .post(
            `${API_URL}/CarePlannerCalendarController/get_patient_scheduled_activities`,
            {
                short_code: short_code,
            }
        )
        .then((r) => r.data);
};
